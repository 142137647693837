import React, { useEffect, useState, createContext } from 'react';
import Profile from './components/profile';
import EditProfile from './components/edit-profile';
import NewChild from './components/new-child';
import ChildrenAccounts from './components/children-accounts';
import ProfileDetail from './components/profile-detail';
import Login from './components/login';
import Invite from './components/invitation';
import { Router, globalHistory } from '@reach/router';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'mobx-react';
import authStore from './lib/stores/auth.store';
import gymStore from './lib/stores/gym.store';
import GymAdmin from './components/admin/gym-admin';
import GymUsers from './components/admin/pages/gym-users';
import GymDashBoard from './components/admin/pages/gym-dashboard';
import firebase from 'firebase';
import Cookies from 'js-cookie';
import get from 'lodash.get';
import Help from './components/help';
import KioskCheckin from './components/admin/pages/kiosk-checkin';
import Privacy from './components/privacy';
import ReactGA from 'react-ga4';
import DisabledUsers from './components/admin/pages/disabled-users';
import Promotions from './components/admin/pages/promotions';
import NewPromotion from './components/admin/pages/new-promotion';
import EditPromotion from './components/admin/pages/edit-promotion';

export const AppContext = createContext();
ReactGA.initialize('G-8V8789W135');
const AppProvider = ({ value, children }) => {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#03a9f4',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#67daff',
      main: '#03a9f4',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },

  typography: {
    // Use the system font instead of the default Roboto font.
    useNextVariants: true,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const App = () => {
  const [loggedInUserId, setLoggedInUserId] = useState(null);

  const fetchAuthToken = async () => {
    firebase.auth().onAuthStateChanged(async (authInfo) => {
      if (authInfo && authInfo.uid) {
        setLoggedInUserId(authInfo.uid);
        const idTokenObject = await authInfo.getIdTokenResult(true);
        const idTokenResult = get(idTokenObject, 'token', '');
        Cookies.set('authToken', idTokenResult);
        return idTokenResult;
      }
    });
  };

  useEffect(() => {
    fetchAuthToken();
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('ready');
    }
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      ReactGA.send({
        hitType: 'pageview',
        page: `${location.pathname} ${location.search}`,
      });
    });
  }, []);

  return (
    <Provider userStore={authStore} gymStore={gymStore}>
      <AppProvider value={{ loggedInUserId }}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <Profile path="/profile">
              <ProfileDetail path=":userId" />
              <ProfileDetail path=":userId/:child" />
              <EditProfile path=":userId/edit" />
              <EditProfile path=":userId/edit/:signup" />
              <NewChild path=":userId/children/:child" />
              <ChildrenAccounts path=":userId/children" />
            </Profile>
            <Help path="/help" />
            <Login path="/" />
            <Login path="/login/:gymId" />
            <Login path="/login" />
            <Invite path="/invite" />
            <Privacy path="/privacy" />
            <GymAdmin path="/admin">
              <GymDashBoard path="dashboard/:gymUid" />
              <GymUsers path="gyms/:gymUid/users" />
              <DisabledUsers path="gyms/:gymUid/disabled-users" />
              <KioskCheckin path="gyms/:gymUid/kiosk" />
              <Promotions path="gyms/:gymUid/promotions" />
              <NewPromotion path="gyms/:gymUid/promotions/new" />
              <EditPromotion path="gyms/:gymUid/promotions/edit/:promoId" />
            </GymAdmin>
            <Help path="/remove-account" />
          </Router>
        </MuiThemeProvider>
      </AppProvider>
    </Provider>
  );
};

export default App;
