import React, { useState } from 'react';
import PageLayout from './page-layout';
import { Flex, Box } from '@rebass/grid';
import { Button, TextField } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const Help = () => {
  const [email, setEmail] = useState('');

  const handleStoreEmail = async () => {
    try {
      const db = firebase.firestore();
      await db.collection('account_deletion_requests').add({
        email: email,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
      alert('Your request has been submitted.');
    } catch (error) {
      console.error('Error storing email:', error);
      alert('There was an error submitting your request. Please try again.');
    }
  };

  const handleCancel = () => {
    console.log('cancel');
  };

  return (
    <PageLayout selected="help" pageTitle="Help">
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        mb="16px"
      >
        <h2>Remove Account</h2>
        <p>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </p>
        <Box mt="16px">
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </Box>
        <Box mt="16px">
          <Button
            onClick={handleStoreEmail}
            variant="contained"
            color="primary"
          >
            Yes, Submit Request
          </Button>
        </Box>
        <Box mt="8px">
          <Button onClick={handleCancel} variant="outlined" color="secondary">
            Cancel
          </Button>
        </Box>
      </Flex>
    </PageLayout>
  );
};

export default Help;
